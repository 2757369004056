import './tracking.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import React, { Component, useState, useEffect }  from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { useNavigate, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import Notallowed from '../myaccount/Notallowed';
import {isLoggedIn, getPrivacyStandard} from '../helpers';
import Trackingbyclients from './TrackingByClients';
let userData = isLoggedIn(Cookies);
let privacyControl = getPrivacyStandard(Cookies);

let domainOrigin = "";
let origin = window.origin;
if(origin.includes("reporting.milgenx.com")){
    domainOrigin = "https://reportingapi.milgenx.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

const Tracking = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    let [listCount, setListCount] = useState(1);

    useEffect(() => {
        const getCount = async () => {
        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);
        // Await make wait until that 
        // promise settles and return its result
            let requestUrI = domainOrigin+"/daily-reporting";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            // After fetching data stored it in posts state.
            if(response.status === 200){
                let result = response.data;
                setIsLoaded(true);
                setItems(result.reverse());
            }
        }
        getCount()
        // forceUpdate();
    }, [listCount])

    const navigate = useNavigate();
    if(userData.loggedIn === 'true' && Number(userData.accessAllowed) === 1){
    return (
        <div className='leads-board full-width'>
            <TabView className="p-0">
                <TabPanel header="Tracking By Client(User)" className="p-0">    
                    <div className='container-fluid full-width'>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-12 p-0  bg-white'>
                                <Trackingbyclients items={items} viewMode="only-user" userDetails={userData} />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                
                {privacyControl == false &&
                    <TabPanel header="Tracking By Client(All)" className="p-0">    
                        <div className='container-fluid full-width'>
                            <div className='row'>
                                <div className='col-12 col-md-12 col-lg-12 p-0 bg-white'>
                                    <Trackingbyclients items={items} viewMode="all" userDetails={userData} />
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                }
                
                {/* <TabPanel header="Performance" className="p-0">  
                    <Reporting />
                </TabPanel>
                <TabPanel header="Analysis" className="p-0">  
                    <Recordsanalysis />
                </TabPanel> */}
            </TabView>
        </div>
    )
    }else{
        navigate("/my-account");
        return(
            <Notallowed/>
        )
    }
}
export default Tracking;