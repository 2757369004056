import React, { useState, useEffect, Component, PureComponent }  from 'react';
import { Brush, AreaChart, Area, LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { sumOfColumn, splitRows, splitColumn, removeDuplicates, sumValues, compareStrings } from '../helpers';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import axios, {isCancel, AxiosError} from 'axios';
// import { classNames } from 'primereact/utils';
let domainOrigin = "";
let origin = window.origin;
if(origin.includes("reporting.milgenx.com")){
    domainOrigin = "https://reportingapi.milgenx.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

const Recordsanalysis = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [ignored, forceUpdate] = useState("");
    const [dates, setDates] = useState([]);
    // const [datesInfo, setDatesInfo] = useState(null);
    // let [startDate, setStartDate] = useState("");
    // let [endDate, setEndDate] = useState("");

    let startDate, endDate = "";
    let datesInfo = null;

    useEffect(() => {
        const getCount = async () => {
        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);
        // Await make wait until that 
        // promise settles and return its result
            let requestUrI = domainOrigin+"/daily-reporting";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            // After fetching data stored it in posts state.
            if(response.status === 200){
                let result = response.data;
                setIsLoaded(true);
                setItems(result);
                // console.log(result);
            }
        }
        getCount()
        // forceUpdate()
    }, [])

    // const [resData, setResData ] = useState([]); 

    const [filterParam, setFilterParam] = useState({
        productName : "", camaignGroup : "", campaignSource : ""
    });
 
    let Allproducts = removeDuplicates(splitColumn(items, "product_name"));
    const products = [{name: "All Product", code: "all-product"}];
    Allproducts.forEach(function(product){
        products.push({name: product, code: product.toLowerCase()})
    })

    let activeLeadList = [];
    // Filtered lead list related to selected Product.
    // Filtered lead list related to selected Product & campaign group.
    let activeCampaignGroupLeads = "";
    // Filtered lead list related to selected Product, campaign group & campaign source.
    let activeCampaignSourceLeads = "";
    let campaignListItems = [];
    let camaignSourceItems = [];
    let pannelHeading = "";

    let selectedProductName = filterParam.productName.name;
    let selectedCamaignGroup = filterParam.camaignGroup.name;
    let selectedCampaignSource = filterParam.campaignSource.name;
    let groupLeadsDate = [];
    let sourceLeadsDate = [];
    let groupLeadsByDate = [];
    let sourceLeadsByDate = [];
    activeLeadList = splitRows(items, "product_name", selectedProductName);

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFilterParam({...filterParam, [name] : value });
    }

    if(selectedProductName !== undefined ){
        if(selectedProductName === "All Product"){
            activeLeadList = items;
        }else{
            activeLeadList = splitRows(items, "product_name", selectedProductName);
        }
        if(activeLeadList.length > 0){

            // leadDateRange
            let leadDateRange = [];
            let dateRange = removeDuplicates(splitColumn(activeLeadList, "date"));
            dateRange.forEach(function(dateRangeItem){
                leadDateRange.push(dateRangeItem.split("T")[0])
            })

            let firstDayRaw = leadDateRange[0];
            let lastDayRaw = leadDateRange[leadDateRange.length - 1];

            if(firstDayRaw){
                let firstDay = firstDayRaw.split("-");
                let minDate = new Date();
                minDate.setFullYear(firstDay[0]);
                minDate.setMonth(firstDay[1] - 1);
                minDate.setDate(firstDay[2]);
                // setStartDate(minDate);
                startDate = minDate;
            }
         
            if(lastDayRaw){
                let lastDay = lastDayRaw.split("-");
                let maxDate = new Date();
                maxDate.setMonth(lastDay[1] - 1);
                maxDate.setFullYear(lastDay[0]);
                maxDate.setDate(lastDay[2]);
                // console.log({maxDate, lastDay})
                endDate = maxDate;
                datesInfo = "From "+firstDayRaw+" to "+lastDayRaw;
                // setDatesInfo("From "+firstDayRaw+" to "+lastDayRaw);
            }

            let begingingDate = new Date(firstDayRaw);
            // console.log({begingingDate})
            // setDates(maxDate);

            // console.log({dateRange, leadDateRange, activeLeadList})

            let camaignList = removeDuplicates(splitColumn(activeLeadList, "campaign_group"));
            campaignListItems = [{name: "Select type of campaign", code: undefined}];
            camaignList.forEach(function(camaignListItem){
                campaignListItems.push({name: camaignListItem, code:camaignListItem.toLowerCase()})
            })
            // setCampaignGroup(campaignListItems);
    
            // setCampaignSource
            if(selectedCamaignGroup){
                let allCampaignGroup = splitRows(activeLeadList, "campaign_group", selectedCamaignGroup);
                let camaignSource = removeDuplicates(splitColumn(allCampaignGroup, "campaign_source"));
                camaignSourceItems = [{name: "Select source of campaign", code: undefined}];
                camaignSource.forEach(function(camaignSourceItem){
                    camaignSourceItems.push({name: camaignSourceItem, code:camaignSourceItem.toLowerCase()})
                })
            }
            
            // setcamaignSource(camaignSourceItems);
            if(activeLeadList.length > 0){
                if(selectedCamaignGroup !== "Select type of campaign" && selectedCamaignGroup !== undefined){
                    activeCampaignGroupLeads = splitRows(activeLeadList, "campaign_group", selectedCamaignGroup);
                }else{
                    activeCampaignGroupLeads = activeLeadList;
                }
                if(activeCampaignGroupLeads.length > 0){
                    if(activeCampaignGroupLeads.length > 0){
                        activeCampaignGroupLeads.forEach(function(activeCampaignGroupLeadsItem){
                            let campaignGroupLeadsDate = activeCampaignGroupLeadsItem.date.split("T")[0];
                            groupLeadsDate.push({date: campaignGroupLeadsDate});
                        })
                        let groupLeadsUniqueDates = removeDuplicates(splitColumn(groupLeadsDate, "date"));
                        // console.log(groupLeadsUniqueDates)
                        groupLeadsUniqueDates.forEach(function(groupLeadsUniqueDate){
                            let campaignGroupLeadsDate = "";
                            // console.log("----");
                            let leadCountItem = 0;
                            let perLeadCost = 0;
                            let leadCost = 0;
                            let countSumInitlizer = 0;
                            let costSumInitlizer = 0;
                            activeCampaignGroupLeads.forEach(function(activeCampaignGroupLeadsItem){
                                campaignGroupLeadsDate = activeCampaignGroupLeadsItem.date.split("T")[0];
                                if(campaignGroupLeadsDate === groupLeadsUniqueDate){
                                    // console.log({campaignGroupLeadsDate, groupLeadsUniqueDate})
                                    leadCountItem = Number(countSumInitlizer + activeCampaignGroupLeadsItem.lead_count);
                                    countSumInitlizer = leadCountItem;
                                    leadCost = Number(costSumInitlizer + activeCampaignGroupLeadsItem.lead_cost);
                                    costSumInitlizer = leadCost;
                                    // console.log({leadCost, leadCountItem});
                                }
                            })
                            // console.log({leadCountItem, countSumInitlizer})
                            perLeadCost = Math.round(leadCost / leadCountItem);
                            // console.log({leadCost, leadCountItem, perLeadCost})
                            groupLeadsByDate.push({date:groupLeadsUniqueDate, "Lead Count": leadCountItem, "Lead Cost": leadCost, "Cost Per Lead": perLeadCost});
                        })
                        console.log({groupLeadsByDate});
                    }
                    // console.log({activeCampaignGroupLeads, activeCampaignSourceLeads, selectedCamaignGroup});
    
                    // activeCampaignSourceLeads = splitRows(activeCampaignGroupLeads, "campaign_source", selectedCampaignSource);
                }
            }

            if(selectedCampaignSource){
                activeCampaignSourceLeads = splitRows(activeLeadList, "campaign_source", selectedCampaignSource);
                // console.log({activeCampaignSourceLeads})
                if(activeCampaignSourceLeads.length > 0){
                    activeCampaignSourceLeads.forEach(function(activeCampaignSourceLeadsItem){
                        let campaignSourceLeadsDate = activeCampaignSourceLeadsItem.date.split("T")[0];
                        sourceLeadsDate.push({date: campaignSourceLeadsDate});
                    })
                    let sourceLeadsUniqueDates = removeDuplicates(splitColumn(sourceLeadsDate, "date"));
                    
                    // console.log(sourceLeadsUniqueDates)
                    sourceLeadsUniqueDates.forEach(function(sourceLeadsUniqueDate){
                        let campaignGroupLeadsDate = "";
                        let leadCountItem = 0;
                        let perLeadCost = 0;
                        let leadCost = 0;
                        let countSumInitlizer = 0;
                        let costSumInitlizer = 0;
                        let leadSource = "";
                        // console.log("----");
                        activeCampaignSourceLeads.forEach(function(activeCampaignSourceLeadsItem){
                            campaignGroupLeadsDate = activeCampaignSourceLeadsItem.date.split("T")[0];
                            if(campaignGroupLeadsDate === sourceLeadsUniqueDate){
                                leadCountItem = Number(countSumInitlizer + activeCampaignSourceLeadsItem.lead_count);
                                leadSource = activeCampaignSourceLeadsItem.campaign_source;
                                leadCost = Number(costSumInitlizer + activeCampaignSourceLeadsItem.lead_cost);
                                perLeadCost = Math.round(leadCost / leadCountItem);
                                console.log({leadCountItem, perLeadCost, leadCost, });
                            }
                        })
                        sourceLeadsByDate.push({date:sourceLeadsUniqueDate, leadSource: leadSource, "Lead Count": leadCountItem, "Lead Cost": leadCost, "Cost Per Lead": perLeadCost});
                    })
                }
            }
            
        }
    }

    let data = sourceLeadsByDate;;
    // console.log({data, sourceLeadsByDate})
    if(selectedProductName){
        if(selectedCamaignGroup !== "Select type of campaign" && selectedCampaignSource === "Select source of campaign" || selectedCampaignSource === undefined){
            data = groupLeadsByDate;
            pannelHeading = "Showing Analysis of "+selectedProductName+" for Spent on "+selectedCamaignGroup;
            // console.log({groupLeadsByDate});
        }else if(selectedCamaignGroup !== "Select type of campaign" && selectedCampaignSource !== "Select source of campaign"){
            data = sourceLeadsByDate;
            pannelHeading = "Showing analysis of "+selectedProductName+" for amount spent on "+selectedCamaignGroup+" of campaign on "+selectedCampaignSource;
            // console.log({sourceLeadsByDate});
        }
    }else{
        pannelHeading = "Select a product & campaign group for analysis";
    }

    let selectedDates = dates;
    let sortedSelectedDates = [];
    let dataByDates = [];
    if(selectedDates){
        selectedDates.forEach(function(selectedDate){
            let rawDate = selectedDate.toLocaleDateString("en-IN").split("/");
            let date = String(rawDate[2]+rawDate[1]+rawDate[0]);
            // console.log("----");
            data.forEach(function(item){
                console.log("1")
                let itemDateRaw = new Date(item.date).toLocaleDateString("en-IN").split("/");
                let itemDate1 = String(itemDateRaw[2]+itemDateRaw[1]+itemDateRaw[0])
                let itemDate = itemDate1;
                // console.log(typeof date, typeof itemDate);
                // console.log(date, itemDate, compareStrings(date, itemDate))
                // console.log(compareStrings(itemDate, date))
                if(itemDate == date){
                    console.log("gggggg")
                    console.log(typeof item)
                    if(typeof item === NaN){
                        // dataByDates.push(item);
                    }else{
                        dataByDates.push(item);
                    }
                    // console.log({itemDate, date, item})
                    // alert(1)
                }
            })
        })
        // console.log({dataByDates, selectedDates, items});
    }

    if(dataByDates.length > 0){
        data = dataByDates;
    }

    let allLeadsCost = sumOfColumn(splitColumn(data, "Lead Cost"));
    let allLeadsCount = sumOfColumn(splitColumn(data, "Lead Count"));
    let HighestCostPerLead = Math.max(...splitColumn(data, "Cost Per Lead"));
    let AverageCostPerLead = Math.round(splitColumn(data, "Cost Per Lead").reduce((a, b) => a + b, 0) / data.length);
    // console.log({data})
    class CustomizedLabel extends PureComponent {
        render() {
            const { x, y, stroke, value } = this.props;
        
            return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                {value}
            </text>
            );
        }
    }
        
    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;
        
            return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                {payload.value}
                </text>
            </g>
            );
        }
    }
        
    const ChartByActiveSource = (props) => {
        let data = props.data;
        if(selectedProductName){
            return (
                <>
                    <div className='row my-2'>
                        <div className='col-6 col-sm-6 col-md-3 mb-2'>
                            <div className='status-block w-full bg-blue'>
                                <small>Amt Spent: </small>
                                <strong>&#8377;{allLeadsCost}</strong>
                            </div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-2'>
                            <div className='status-block w-full bg-blue'>
                                <small>Total Leads: </small>
                                <strong>{allLeadsCount}</strong>
                            </div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-2'>
                            <div className='status-block w-full bg-blue'>
                                <small>Highest Lead Cost: </small>
                                <strong>&#8377;{HighestCostPerLead}</strong>
                            </div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 mb-2'>
                            <div className='status-block w-full bg-blue'>
                                <small>Avg Lead Cost: </small>
                                <strong>&#8377;{AverageCostPerLead}</strong>
                            </div>
                        </div>
                    </div>
                    <ResponsiveContainer width="100%" height={200}>
                    <LineChart
                    width={500}
                    height={200}
                    data={data}
                    syncId="anyId"
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="Cost Per Lead" stroke="#8884d8" fill="#8884d8"  label={<CustomizedLabel />} />
                    </LineChart>
                    </ResponsiveContainer>
                    <p className='w-full text-center'>Trends of <b>Cost Per Lead</b> By Dates</p>

                    <ResponsiveContainer width="100%" height={200}>
                    <LineChart
                    width={500}
                    height={200}
                    data={data}
                    syncId="anyId"
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="Lead Cost" stroke="#82ca9d" fill="#82ca9d"  label={<CustomizedLabel />} />
                    <Brush />
                    </LineChart>
                    </ResponsiveContainer>
                    <p className='w-full text-center'>Trends of <b>Lead Cost</b> By Dates</p>

                    <ResponsiveContainer width="100%" height={200}>
                    <AreaChart
                    width={500}
                    height={200}
                    data={data}
                    syncId="anyId"
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="Lead Count" stroke="#82ca9d" fill="#82ca9d"  label={<CustomizedLabel />} />
                    </AreaChart>
                    </ResponsiveContainer>
                    <p className='w-full text-center'>Trends of <b>Lead Count</b> By Dates</p>
                </>
            )
        }
    }
    
    return (
        <div className='full-width'>
            <div className='container-fluid py-2 bg-grey'>
                <form id="addcampaign_form" className='row form-theme'>
                    <div className="col-6  col-sm-4 col-md-3 field-row">
                        <Dropdown value={filterParam.productName} onChange={handleInput} options={products} optionLabel="name" name="productName" placeholder="Select Product" className="w-full" />
                    </div>
                    <div className="col-6 col-sm-4 col-md-3 field-row">
                        <Dropdown value={filterParam.camaignGroup} onChange={handleInput} options={campaignListItems} optionLabel="name" name="camaignGroup" placeholder="Select Campaign Group " className="w-full" />
                    </div>
                    <div className="col-12 col-sm-4 col-md-3 field-row">
                        <Dropdown value={filterParam.campaignSource} onChange={handleInput} options={camaignSourceItems} optionLabel="name" name="campaignSource" placeholder="Select Campaign Source" className="w-full" />
                    </div>
                    <div className="col-12 col-sm-12 col-md-3 field-row">
                        <Calendar className="" value={dates} onChange={(e) => setDates(e.value)} name='dateRange' minDate={startDate} maxDate={endDate} selectionMode="multiple" dateFormat="dd/mm/yy" placeholder='Select Dates' showButtonBar showIcon />
                        <span className="info-label">{datesInfo}</span>
                    </div>
                </form>
            </div>
            <div className='container-fluid py-3'>
                <h6 className='text-center'>{pannelHeading}</h6>
                <div className='row'>
                    <div className='col-md-12 chart-view'>
                        <ChartByActiveSource data={data} labelFirst="Lead Cost" labelSecond="Lead Count" /> 
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Recordsanalysis;