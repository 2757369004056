import axios from 'axios';
import React, { Component, useState, useEffect }  from 'react';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import 'primeicons/primeicons.css';
import Managestaff from "../myaccount/Managestaff";
import Cookies from 'js-cookie';
import {isLoggedIn, getPrivacyStandard} from '../helpers';
import Billinganalysis from '../analysis/Billinganalysis';

let userData = isLoggedIn(Cookies);
let userRole = userData.userRole;
let privacyControl = getPrivacyStandard(Cookies);

let domainOrigin = "";
let origin = window.origin;
if(origin.includes("reporting.milgenx.com")){
    domainOrigin = "https://reportingapi.milgenx.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

const Details = ({ userData }) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    let [listCount, setListCount] = useState(1);

    useEffect(() => {
        const getCount = async () => {
        // Till the data is fetch using API 
        // the Loading page will show.
        // setLoading(true);
        // Await make wait until that 
        // promise settles and return its result
            let requestUrI = domainOrigin+"/daily-reporting";
            setIsLoaded(false);
            const response = await axios.get(requestUrI);
            // After fetching data stored it in posts state.
            if(response.status === 200){
                let result = response.data;
                setIsLoaded(true);
                setItems(result.reverse());
            }
        }
        getCount()
        // forceUpdate();
    }, [listCount])

    console.log({userData})
    return (
        <div className='my-account full-width'>
            <div className='row'>
                <div className='col-12 col-md-12 col-lg-12 float-none'>
                    <TabView className="full-width" activeIndex={0}>
                        <TabPanel header="Billing Analysis" className="p-0">    
                            <div className='container-fluid full-width'>
                                <div className='row'>
                                    <div className='col-12 col-md-12 col-lg-12 p-0  bg-white'>
                                        <Billinganalysis items={items} viewMode="only-user" userDetails={userData} />
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel style={{display:'flex',justifyContent:'flex-start',alignItems:'flex-start',flexDirection:'column'}} header="Profile" className="">
                            <div className='full-width container-fluid'>
                                <div className='row'>
                                    <div className='col-12 col-lg-3 col-xl-3 col-md-5 col-sm-6'>
                                        <div className='row user-info w-100 py-0'>
                                            <div className='info-row'><span className='info-label'>Employee ID:</span>{Cookies.get('userEmployeeID')}</div>
                                            <div className='info-row'><span className='info-label'>Name:</span>{Cookies.get('userName')}</div>
                                            <div className='info-row'><span className='info-label'>Email:</span>{Cookies.get('userEmail')}</div>
                                            <div className='info-row'><span className='info-label'>Department:</span>{Cookies.get('userDepartment')}</div>
                                            <div className='info-row'><span className='info-label'>Phone:</span>{Cookies.get('employee_phone')}</div>
                                            <div className='info-row'><span className='info-label'>Role:</span>{Cookies.get('user_role')}</div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-9 col-xl-9 col-md-7 col-sm-6'>
                                        <strong>Activity Log</strong>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        {privacyControl == false && 
                            <TabPanel style={{display:'flex',justifyContent:'flex-start',alignItems:'flex-start',flexDirection:'column'}} header="Staff" className="">
                                <Managestaff />
                            </TabPanel>
                        }
                    </TabView>
                </div>
            </div>
        </div>
    );
}

export default Details;
