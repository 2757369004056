export function domainOriginSource(arr){
    let origin = window.origin;
    let domainOriginSource = "";
    if(origin.includes("reporting.milgenx.com")){
        domainOriginSource = "https://reportingapi.milgenx.com";
    }else{
        domainOriginSource = "http://localhost:8080";   
    }
    return domainOriginSource;
}

export function sumOfColumn(arr){
    let count = 0;
    let countSumInitlizer = 0;
    arr.forEach(function(arrItem){
        count = countSumInitlizer + Number(arrItem);
        countSumInitlizer = count;
    })
    return count;
}

export function splitColumn(arr, arraylabel) {
    // console.log(arr, arraylabel);
    let newArray = [];
    if(arraylabel){
        arr.forEach(function(arrItem, index){
            let splitColumnItem = arrItem[arraylabel];
            newArray.push(splitColumnItem);
        })
        return newArray;
    }else{
        return "arraylabel to split coulmn not defined.";
    }
}

export function splitRows(arr, matchWith, arraylabel) {
    let newArray = [];

    if(arraylabel){
        arr.forEach(function(arrItem, index){
            if(arrItem[matchWith] === arraylabel){
                newArray.push(arrItem);
            }
        })
        return newArray;
    }else{
        return "arraylabel to split rows not defined.";
    }
}

export function removeDuplicates(arr) {
    return [...new Set(arr)];
}

export function sumValues(arr){
    arr.reduce((accumulator, object) => {
    return accumulator + object.salary;
    }, 0);
}

export function parseDate(date){
    if(date){
        let parsedDate = '';
        let dateRaw = date.toLocaleString('en-IN', {timeZone: 'IST'}).split(',')[0].split("/");
        return parsedDate = dateRaw[1]+"/"+dateRaw[0]+"/"+dateRaw[2];
    }else{
        return "";
    }
}

export function extractDate(date){
    if(date){
        return date.split('T')[0];
    }
}

export function getDaysArray(start, end) {
    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
};

export function compareStrings(s1, s2){
   
    // This condition will return true only if s1 and s2 hold true from equality
    if(s1 == s2){
        return true;
    }
    
    return false;
}

export function isLoggedIn(Cookies){
    let userEmail = Cookies.get('userEmail');
    let userName = Cookies.get('userName');
    let loggedIn = Cookies.get('loggedIn');
    let accessAllowed = Cookies.get('access_allowed');
    let userRole = Cookies.get('user_role');
    let userDepartment = Cookies.get('userDepartment');
    let userData = {loggedIn: loggedIn, userEmail: userEmail, userName: userName, accessAllowed: accessAllowed, userRole: userRole, userDepartment: userDepartment}
    return userData;
} 

export function getPrivacyStandard(Cookies){
    let userRole = Cookies.get('user_role');
    console.log({userRole})
    let privacyControl = true;
    if(userRole == "founder" || userRole == "co-founder"){
        privacyControl = false;
    }else {
        privacyControl = true;
    }
    return privacyControl;
}
// https://medium.com/@atif.ali.ati/user-session-in-react-js-aa749bc4faf6#:~:text=We%20can%20initiate%20our%20session,WEBSQL%2C%20LOCALSTORAGE%20or%20COOKIES%20like%E2%80%A6

// export function userProfile(prop){
//     var userName = "";
    
//     var getUserEmail = Cookies.get('userEmail');
//     var getUserName = Cookies.get('userName');
//     var loggedIn  = () => {
//         return Cookies.get('loggedIn');    // Or pull this from cookie/localStorage
//     };
    
//     var setUserName  = (name) => {
//         console.log("Hello")
//         console.log({name})
//         setUserName = name;
//         Cookies.set('userName',setUserName);
//         // Also set this in cookie/localStorage
//     };

//     var setUserEmail  = (email) => {
//         setUserEmail = email;
//         Cookies.set('userEmail',setUserEmail);
//         // Also set this in cookie/localStorage
//     };
    
//     return {
//         props.getUserEmail = getUserEmail;
//         getUserName: getUserName,
//         loggedIn: loggedIn
//     }
// }
// export default userProfile;

export function kindOfContracts(){
    let typeOfContracts = [{name: "Billed by Hours", code: "billable"}, {name: "Non Billed Hours", code: "non-billable"}, {name: "Fixed Cost Project", code: "fixed-cost"}];
    return typeOfContracts;
}

export function typeOfProjects(){
    let typeOfProjects = [{name: "Billed by Hours", code: "billable"}, {name: "Fixed Cost Project", code: "fixed-cost"}];
    return typeOfProjects;
}

export function statusOfProjects(){
    let statusOfProjects = [{name: "In Discussion", code: "in-discussion"}, {name: "In Progress", code: "in-progress"}, {name: "In Review", code: "in-review"}, {name: "Payment Pending", code: "payment-pending"}, {name: "Canceled", code: "canceled"}, {name: "Completed", code: "completed"}];
    return statusOfProjects;
}