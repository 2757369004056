import logo from './logo.svg';
import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Routes, Route, Link } from 'react-router-dom';
import Recordsbysource from './daily-reporting/Recordsbysource';
import Myaccount from './myaccount/Myaccount';
import Mydashboard from './myaccount/Mydashboard';
import Myaccountheader from './myaccount/Myaccountheader';
import SidebarNav from './myaccount/Sidebarnav';
import Projects from './projects/Projects';
import {isLoggedIn} from './helpers'
import Cookies from 'js-cookie';
import Tracking from './tracking/Tracking';
import TrackingInDetail from './tracking/TrackingInDetail';
import Analysis from './analysis/Analysis';
let userData = isLoggedIn(Cookies);
const App = () => {
  let sidebarNav = "";
  if(userData.loggedIn === 'true'){
    sidebarNav = <SidebarNav />; 
  }
  return (
    <Router>
        <div className='leads-board view-full-screen'>
            <div className='container-fluid view-full-screen'>
                <div className='row view-full-screen'>
                    {sidebarNav}
                    <div className='main-area report-view col-12 col-md-9 col-lg-10 d-flex flex-column justify-content-start align-items-start text-left'>
                      <Myaccountheader />
                      <div className='py-2 full-width'>
                        <Routes>
                            <Route path='/' element={<Recordsbysource />} />
                            <Route path='/daily-records' element={<Recordsbysource />} />
                            <Route path='/my-account' element={<Myaccount />} />
                            <Route path='/dashboard' element={<Mydashboard />} />
                            <Route path='/tracking' element={<Tracking />} />
                            <Route path='/tracking/detail' element={<TrackingInDetail />} />
                            <Route path='/analysis' element={<Analysis />} />
                            <Route path='/projects' element={<Projects />} />
                        </Routes>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <Leads /> */}
    </Router>
  );
}

export default App;
